<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Apply the Q test to the following set of data and determine whether the outlying result
        should be retained or rejected at the
        <stemble-latex content="$95\%$" />
        confidence level.
      </p>

      <p class="mb-2">
        <stemble-latex content="$12.47,\,$" />
        <stemble-latex content="$12.67,\,$" />
        <stemble-latex content="$12.48,\,$" />
        <stemble-latex content="$12.56,\,$" />
        <stemble-latex content="$12.53$" />
      </p>

      <p class="mb-2">Determine the gap:</p>
      <calculation-input v-model="inputs.input1" prepend-text="$\text{Gap:}$" class="mb-3" />

      <p class="mb-2">Determine the range:</p>
      <calculation-input v-model="inputs.input2" prepend-text="$\text{Range:}$" class="mb-3" />

      <p class="mb-2">Determine the Q-value:</p>
      <calculation-input v-model="inputs.input3" prepend-text="$\text{Q-value:}$" class="mb-3" />

      <p class="mb-2">Should the outlying result be retained or rejected?</p>
      <v-radio-group v-model="inputs.input4" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '../inputs/CalculationInput.vue';

export default {
  name: 'ChemUPEI2210A2Q2',
  components: {
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      options: [
        {text: 'Yes', value: 'yes'},
        {text: 'No', value: 'no'},
      ],
    };
  },
};
</script>
